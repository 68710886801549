import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenefitsComponent } from './benefits.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [BenefitsComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class BenefitsModule { }
