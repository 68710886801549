import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public id: string;
  public imageSrc: any;
  public isUploaded: boolean = false;

  constructor(private http: HttpClient,
              private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.id = params.id;
    });
  }

  ngOnInit() {
  }

  public displayPhoto(fileInput: any) {
    this.isUploaded = false;

    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();

      reader.onload = ((e) => {
        /* tslint:disable */
        this.imageSrc = e.target['result'];
        const fileExtension = fileInput.target.files[0].name.split(".").pop();
        console.log(fileExtension);

        console.log('Start Upload image ...');
        this.postMethod(e.target['result'], fileExtension);
        this.isUploaded = true;
        console.log('Finish Upload image ...');
        /* tslint:enable */
      });

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  postMethod(pBase64Image: string, pFileExtension: string) {
    const arr = {
      id: this.id,
      image: pBase64Image,
      fileExtension: pFileExtension
    };

    this.http.post('https://quynp5elah.execute-api.eu-west-1.amazonaws.com/live/photos', JSON.stringify(arr)).subscribe((val) => {
      console.log(val);
    });
    return false;
  }

}
