import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckInComponent } from './check-in.component';
import { RouterModule } from '@angular/router';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [CheckInComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ]
})
export class CheckInModule { }
