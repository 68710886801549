import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public name: string;
  public nation: string;
  public selectedRow: number = 7;
  public selectedSeat: string = 'A';

  constructor() { }
}
