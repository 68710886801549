import { Component, OnInit } from '@angular/core';
import {GlobalService} from "../global.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-seat-select',
  templateUrl: './seat-select.component.html',
  styleUrls: ['./seat-select.component.scss']
})
export class SeatSelectComponent implements OnInit {
  public items: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

  constructor(private http: HttpClient,
              public globalService: GlobalService) {
  }

  ngOnInit() {
  }

  public select(row, seat) {
    this.globalService.selectedRow = row;
    this.globalService.selectedSeat = seat;
  }

  public save() {
    this.http.post('/checkin.php', this.globalService).subscribe((val) => {
    });
  }

  public isBlocked(row: number, seat: string) {
    return ['1E', '2C', '6D', '9A', '9B', '9C', '9D', '9E', '9F'].indexOf(row.toString() + seat) > -1;
  }
}
