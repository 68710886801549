import { Component, OnInit } from '@angular/core';
import {GlobalService} from '../global.service';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss']
})
export class CheckInComponent implements OnInit {
  public name: string = null;
  public nation: string = null;
  public isValidationActive: boolean = false;

  constructor(public globalService: GlobalService) {
  }

  ngOnInit() {
    this.name = this.globalService.name;
    this.nation = this.globalService.nation;
  }

  public save() {
    this.globalService.name = this.name;
    this.globalService.nation = this.nation;
  }

}
