import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CheckInComponent} from './check-in/check-in.component';
import {SeatSelectComponent} from './seat-select/seat-select.component';
import {BenefitsComponent} from './benefits/benefits.component';
import {HomeComponent} from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: CheckInComponent
  },
  {
    path: 'check-in',
    component: CheckInComponent
  },
  {
    path: 'seat',
    component: SeatSelectComponent
  },
  {
    path: 'benefits',
    component: BenefitsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
