import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeatSelectComponent } from './seat-select.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [SeatSelectComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class SeatSelectModule { }
