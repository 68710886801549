import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeModule} from './home/home.module';
import {CheckInModule} from './check-in/check-in.module';
import {HttpClientModule} from '@angular/common/http';
import {SeatSelectModule} from './seat-select/seat-select.module';
import {BenefitsModule} from './benefits/benefits.module';
import {FormsModule} from '@angular/forms';
import {GlobalService} from './global.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CheckInModule,
    SeatSelectModule,
    BenefitsModule,
    HttpClientModule,
    FormsModule,
    HomeModule
  ],
  providers: [
    GlobalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
